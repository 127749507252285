<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-body">
		<h2 class="card-title">
			{{pagetitle}}
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/create-vessel']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create Vessel
			</a>
			<button class="btn btn-sm btn-outline-primary float-end me-1" (click)="updateVesselInventory()">
				<fa-icon [icon]="['fas', 'spinner']" [animation]="'spin'" *ngIf="updateInventoryLoader"></fa-icon>
				Update Inventory
			</button>
			<button class="btn btn-sm btn-outline-info float-end me-1" (click)="downloadExcel()">
				<fa-icon [icon]="['fas', 'spinner']" [animation]="'spin'" *ngIf="excelLoader"></fa-icon>
				<fa-icon [icon]="['fas', 'cloud-download-alt']" *ngIf="!excelLoader"></fa-icon>
				Download Excel
			</button>
		</h2>
		<div class="row">
			<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1">
				<ng-select appearance="outline" appendTo="body" [items]="platform_dropdown" bindLabel="name"
					bindValue="val" [clearable]="true" [multiple]="false" [(ngModel)]="filter.platform"
					placeholder="Platform" (change)="refreshTable();">
				</ng-select>
			</div>
			<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1">
				<ng-select appearance="outline" appendTo="body" [items]="companyList_dropdown" bindLabel="name"
					bindValue="id" [clearable]="true" [multiple]="false" [(ngModel)]="filter.company"
					placeholder="Company" (change)="rma_dashboard_fleet_dropdown();refreshTable();">
				</ng-select>
			</div>
			<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
				<ng-select appearance="outline" appendTo="body" [items]="fleetList_dropdown" bindLabel="name"
					bindValue="id" [clearable]="true" [multiple]="false" [(ngModel)]="filter.fleet" placeholder="Fleet"
					(change)="refreshTable();">
				</ng-select>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 12% !important;">Company</th>
								<th style="width: 11% !important;">Fleet</th>
								<th style="width: 10% !important;">Owner</th>
								<th style="width: 12% !important;">Vessel</th>
								<th style="width: 10% !important;">Mail Sent On</th>
								<th style="width: 10% !important;">Email</th>
								<th style="width: 5% !important;" class="text-center">CLIP</th>
								<th style="width: 5% !important;" class="text-center">HC</th>
								<th style="width: 5% !important;" class="text-center">MHS</th>
								<th style="width: 5% !important;" class="text-center">Pharma</th>
								<th style="width: 5% !important;" class="text-center">Training</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.company?v.company.name:"-" }}</td>
								<td>{{ v.fleet?v.fleet.name:"-" }}</td>
								<td>{{ v.vessel_owner?v.vessel_owner.name:"-" }}</td>
								<td>{{ v.name }}</td>
								<td>{{ v.mail_timestamp }}</td>
								<td>
									<span [matTooltip]="v.email">
										{{ clipText(v.email,20) }}
									</span>
								</td>
								<td class="text-center">
									<button *ngIf="v.clip_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from CLIP" id="btnDeleteCLIP{{v.id1}}"
										(click)="deleteConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderCLIP != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.clip_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in CLIP" id="btnCreateCLIP{{v.id1}}"
										(click)="createConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderCLIP != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.hc_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from HC" id="btnDeleteHC{{v.id1}}"
										(click)="deleteConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderHC != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.hc_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in HC" id="btnCreateHC{{v.id1}}"
										(click)="createConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderHC != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.mhs_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from MHS" id="btnDeleteMHS{{v.id1}}"
										(click)="deleteConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderMHS != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.mhs_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in MHS" id="btnCreateMHS{{v.id1}}"
										(click)="createConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderMHS != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.pharma_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from Pharma" id="btnDeletePharma{{v.id1}}"
										(click)="deleteConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderPharma != i"
											[icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.pharma_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in Pharma" id="btnCreatePharma{{v.id1}}"
										(click)="createConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderPharma != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.training_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from Training" id="btnDeleteTraining{{v.id1}}"
										(click)="deleteConfirmTraining(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderTraining != i"
											[icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderTraining == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.training_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in Training" id="btnCreateTraining{{v.id1}}"
										(click)="createConfirmTraining(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderTraining != i"
											[icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderTraining == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td>
									<button class="btn btn-sm btn-outline-success" matTooltip="Send Welcome Mail"
										id="btnMail{{v.id1}}" (click)="openSendMailModal(v.id,i)">
										<fa-icon *ngIf="showMailLoader != i" [icon]="['fas', 'paper-plane']"></fa-icon>
										<fa-icon *ngIf="showMailLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<a class="btn btn-sm btn-outline-primary ms-1" matTooltip="Edit"
										id="btnEdit{{v.id1}}" [routerLink]="['/app/edit-vessel',v.id1]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id1}}" (click)="deleteConfirm(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="12" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>
<div class="modal fade" id="sendMailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content" *ngIf="mailIndex !== null">
			<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="sendMail()" #modform="ngForm">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLabel">Send Welcome Email</h4>
					<button type="button" class="btn-close" aria-label="Close" (click)="clearSendMailModal()"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-lg-6 col-md-6 col-sm-12">
							<label>Vessel: <strong>{{dataList[mailIndex].name}}</strong></label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12">
							<label>Email-Id: <strong>{{dataList[mailIndex].email}}</strong></label>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-lg-1 col-md-1 col-sm-1">
							<label class="mt-3">CC:</label>
						</div>
						<div class="col-lg-11 col-md-11 col-sm-11">
							<phone-email-input formControlName="cc_emails" [type]="'email'" [id]="'cc_emails'"
								[required]="f1.cc_emails.hasValidator(requiredValidator)" [hasError]="false"
								[errors]="f1.cc_emails.errors"></phone-email-input>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-lg-1 col-md-1 col-sm-1">
							<label class="mt-3">BCC:</label>
						</div>
						<div class="col-lg-11 col-md-11 col-sm-11">
							<phone-email-input formControlName="bcc_emails" [type]="'email'" [id]="'bcc_emails'"
								[required]="f1.bcc_emails.hasValidator(requiredValidator)" [hasError]="false"
								[errors]="f1.bcc_emails.errors"></phone-email-input>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" (click)="clearSendMailModal()">
						Cancel
					</button>
					<button type="submit" class="btn btn-primary">
						<fa-icon *ngIf="showMailLoader === null" [icon]="['fas', 'paper-plane']"></fa-icon>
						<fa-icon *ngIf="showMailLoader !== null" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
						Send Mail
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService, HttpParamsOptions } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LegendPosition } from '@swimlane/ngx-charts';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { trigger, style, transition, animate } from '@angular/animations';
import { NgZone } from '@angular/core';
import * as _ from 'lodash';
declare var $: any;
import { DataTableDirective } from 'angular-datatables';
import { Config } from 'datatables.net';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
  selector: 'app-rma-master',
  templateUrl: './rma-master.component.html',
  styleUrl: './rma-master.component.scss',
  animations: [trigger('enterAnimation', [
		transition(':enter', [
			style({ transform: 'translateY(-100%)', opacity: 0 }),
			animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
		]),
		transition(':leave', [
			style({ transform: 'translateY(0)', opacity: 1 }),
			animate('500ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
		])
	])]
})
export class RMAMasterComponent {

UserModuleAccess: any = "Full";
  showCard = false;
  colorScheme: any = {
    domain: ['#5AA454', '#ffe417', '#f72119', '#ffa01b', '#690000']
  };
  colorScheme1: any = {
    domain: ['#211bd1', '#ffe417', '#f72119', '#ffa01b', '#690000']
  };
  yAxisTicks: any[] = [0, 300, 600, 900, 1200];
  filterDates = (d: Date | null) => {
    const today = new Date();
    if (d == null) return false;
    return d <= today;
  }
  // options
  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
  @ViewChild(MatPaginator,) paginator!: MatPaginator;
  baseURL = environment.API_URL + "api/";
  dtOptions: Config = {};
  min_apptDate = new Date();
  dataList: any = [];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = true;
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  containerWidth = 450;
  containerHeight = 420;
  ballSize = 50;
  intervalId: any;
  displayedColumns: string[] = ['sn', 'name', 'value'];  // Columns to display in the table
  dataSource = new MatTableDataSource<any>();        // Data source for the table
  totalItems = 100;                                        // Total number of items (used for pagination)
  pageSize = 10;
  selectDoctor: any = [];
  showDeleteLoader: any = null;
  params: any = {
    // fromDate:new Date(new Date().setDate(new Date().getDate() - 120)),
    // toDate:new Date(),
    selectDoctor: "",
    caseStatus: ""
  };
  caseStatus: any = ['Active', 'Closed', 'Repatriation', 'Reopen'];
  //----------------------//
  user_type: any = "";
  isCompanyIdNull = false;
  accessLoader = false;
  yearList: any[] = [];
  RCodeBlueData: any = [];
  EventFlagDistribution: any;
  MFClosure: any = null;
  mark_for_closure_count: any;
  constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private zone: NgZone, private datePipe: DatePipe, private http: HttpClient) {
    let yyyy = 2000;
    let currentYear = new Date().getFullYear();
    while (yyyy<=currentYear) {
      /* let toYear: any = (yyyy + 1).toString().slice(2); */
      this.yearList.push({ value: yyyy, name: yyyy/*  + "-" + toYear */ });
      yyyy += 1;
    }
    this.yearList = this.yearList.reverse();
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      this.accessLoader = true;
      this.apiService.getUserAccess().subscribe((data: any) => {
        this.accessLoader = false;
        try {
          this.user_type = data.res[0].user_type;
          this.cardsFilter.user_id = (data.res[0].UserId);
          let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
          this.UserModuleAccess = accessObj["HC_dashboard"];
      
          this.getCompanyWiseData();
        } catch (error) { }
      }, (error: any) => {
        this.accessLoader = false;
        this.UserModuleAccess = "NoAccess";
      });
    });
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  // Fetch data from API
  vesselLoader = false;



  initDatatable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      ordering: true,
      ajax: (dataTablesParameters: any, callback) => {
        let data: any = {
          start_date:this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
          end_date:this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
          start:1,
          length:10,
          draw:1,
          mark_for_closure: this.MFClosure
        }
        // if (dataTablesParameters.search.value != "") {
        //   data.searchVal = dataTablesParameters.search.value;
        // }
        const httpParams: HttpParamsOptions = { data } as HttpParamsOptions;
        console.log(httpParams);
        this.http.post<DataTablesResponse>(this.baseURL + "master_hc_dashboard", data).subscribe((response: any) => {

          this.dataList = response.data_list;
          this.mark_for_closure_count = response.mark_for_closure_count;

          callback({
            recordsTotal: response.recordsTotal,
            recordsFiltered: response.recordsFiltered,
            data: [],
            searchVal: dataTablesParameters.search.value,
          });
        }, (error: any) => {
          if (error.status == 401) { return }
          this.toastr.error("Hmmm Something went wrong.", 'Error!!!', {
            timeOut: 5000,
            tapToDismiss: true,
            closeButton: true
          });
        });
      }
    }
  }

  getCompanyWiseData() {
    this.initDatatable();
    this.getEventFlagDistributionByDoctors();
   this.getRmaCodeBlue();
  }

  activeCases: any = [];
  isListFinished: boolean = false;
  activeCasesLoading: boolean = false;
  tableparams = {
    start: 0,
    length: 10,
  };
  ActiveCasesExcelLoader = false;
  ActiveCasesExcelExcelName = "Active Cases.xlsx";


  getRmaCodeBlue(){
    
    this.apiService.rma_dashboard_code_blue_section().subscribe(res => {
      let data:any  = res;
      this.RCodeBlueData = data.data_list;

    }, (error) => {
      this.RCodeBlueData = [];
      if (error.status == 401) { return }
      this.toastr.error("Unable to Fetch Data ", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
    });
  }

  getEventFlagDistributionByDoctors(){
    let data ={
          start_date:this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
          end_date:this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
    }
    
    this.apiService.event_flag_distribution_by_doctors(data).subscribe(res => {
      let data:any  = res;
      this.EventFlagDistribution = data.data_list;

    }, (error) => {
      this.EventFlagDistribution = [];
      if (error.status == 401) { return }
      this.toastr.error("Unable to Fetch Data ", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
    });
  }

  	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

  MarkedForClosure(event: any): void { 
    if(event.checked){
    this.MFClosure = true;
    this.refreshTable();
    this.initDatatable();
    }else{
      this.MFClosure = false;
      this.refreshTable();
      this.initDatatable();
    }
  }
  pagetitle = "RMA";
  breadcrumbList: any[] = [
    {
      path: this.apiService.getHomeURL(),
      name: "Home"
    },
    {
      path: this.apiService.getDashboardURL("hc"),
      name: this.pagetitle
    }
  ];
  dateFilter = this.apiService.getDefaultDateRange(true);
  currentAccordion = "InvoiceModuleAlerts";
  /* symptoms: string[] = ['ILLNESS', 'PSYCHOLOGICAL', 'SURGERY', 'INJURY', 'ROUTINE HEALTH CHECK']; */
  /* humanAnatomyFilters: any = {
    symptom: { group_name: "INJURY", hc_id: 255 },
    sub_symptom: null
  } */


  top_5_med_cases: any[] = [];
  top5CasesLoader = false;
  rma_dashboard_top_5_med_cases_graph(): void {
    const filter = {
      company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.hc_id : null,
      owner_id: this.cardsFilter.owner_id ? this.cardsFilter.owner_id.hc_id : null,
      start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
      end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
      rank: this.cardsFilter.rank,
      nationality: this.cardsFilter.nationality,
      fleet_id: this.cardsFilter.fleet_id ? this.cardsFilter.fleet_id.hc_id : null,
      vessel_id: this.cardsFilter.vessel_id,
      symptom: this.cardsFilter.symptom ? this.cardsFilter.symptom.group_name : null,
      sub_symptom: this.cardsFilter.sub_symptom ? this.cardsFilter.sub_symptom.group_name : null,
    };
    this.top5CasesLoader = true;
    this.apiService.rma_dashboard_top_5_med_cases_graph(filter).subscribe(({ data_list }: any) => {
      this.top5CasesLoader = false;
      this.top_5_med_cases = data_list.map(({ name, value }: any) => ({ name, value }));
    }, () => {
      this.top5CasesLoader = false;
      this.top_5_med_cases = [];
    }
    );
  }

  cardsFilter: any = {
    company_id: null,
    fleet_id: null,
    vessel_id: null,
    nationality: null,
    rank: null,
    start_date_analytics: this.apiService.getDefaultDateRange1Month(false).start_date,
    end_date_analytics: this.apiService.getDefaultDateRange1Month(false).end_date,
    user_id: null,
    start_date: this.apiService.getDefaultDateRange1Month(false).start_date,
    end_date: this.apiService.getDefaultDateRange1Month(false).end_date,
    owner_id: null,
    symptom: { group_name: "INJURY", hc_id: 255 },
    sub_symptom: null,
    year: new Date().getFullYear()
  }
  arc_width = 0.25;
  pieChartLegendPosition: LegendPosition = LegendPosition.Right;
  pieChartLegendPositionBelow: LegendPosition = LegendPosition.Below;

  colorArr = ["#198754", "#FFC107", "#0D6EFD", "#0DCAF0", "#DC3545", "#6C757D", "#6b5b95", "#86af49",
    "#c94c4c", "#bc5a45", "#EF46DD", "#D75D5E", "#4B0082", "#005792", "#6643b5"
  ];

  dateFormat(date: { getFullYear: () => any; getMonth: () => number; getDate: () => string; }) {

    const year = date.getFullYear();
    const month = ('00' + (date.getMonth() + 1)).slice(-2); // Pad with zero
    const day = ('00' + date.getDate()).slice(-2); // Pad with zero
    return `${year}-${month}-${day}`;
  }

  getTime() {
    let formattedDate: any = this.datePipe.transform(new Date(), 'dd/MM/YYYY, h:mm:ss a');
    formattedDate = formattedDate.replaceAll(/[, ]/g, '__');
    return formattedDate;
  }

}

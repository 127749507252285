import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';
import * as FileSaver from 'file-saver';
declare var $: any;

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-vessel-list',
	templateUrl: './vessel-list.component.html',
	styleUrl: './vessel-list.component.css'
})
export class VesselListComponent implements OnInit, OnDestroy, AfterViewInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/vessel",
			name: "Vessels"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "mdm/";
	dtOptions: Config = {};
	dataList: any = [];
	showDeleteLoader: any = null;
	showMailLoader: any = null;
	showDeleteLoaderHC: any = null;
	showCreateLoaderHC: any = null;
	showDeleteLoaderCLIP: any = null;
	showCreateLoaderCLIP: any = null;
	showDeleteLoaderMHS: any = null;
	showCreateLoaderMHS: any = null;
	showDeleteLoaderPharma: any = null;
	showCreateLoaderPharma: any = null;
	showDeleteLoaderTraining: any = null;
	showCreateLoaderTraining: any = null;
	pagetitle = "Vessel List";
	pageLoading = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	passwordId: any;
	resetPasswordForm!: FormGroup;
	showReset2FALoader: any = null;
	updateInventoryLoader = false;
	excelLoader = false;
	fleetList_dropdown: any = [];
	companyList_dropdown: any = [];
	platform_dropdown: any = [
		{ name: "HC", val: "HC" },
		{ name: "CLIP", val: "CLIP" },
		{ name: "MHS", val: "MHS" },
		{ name: "Pharma", val: "Pharma" },
		{ name: "Training", val: "Training" }
	];
	searchVal: any = "";
	filter: any = {
		company: null,
		fleet: null,
		platform: null
	}
	user_type: any = "";
	accessLoader = false;
	moduleForm: FormGroup = this.formBuilder.group({
		cc_emails: [null],
		bcc_emails: [null]
	});
	requiredValidator = Validators.required;

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				/* let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"]; */
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			/* this.UserModuleAccess = "NoAccess"; */
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.rma_dashboard_company_dropdown();
			this.initDatatable();
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, password_validation]],
				confirmPassword: ['', [Validators.required]],
			});
		});
	}

	ngOnDestroy(): void {

	}

	ngAfterViewInit(): void {

	}

	rma_dashboard_company_dropdown() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			this.companyList_dropdown = data.data;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}

	rma_dashboard_fleet_dropdown() {
		this.filter.fleet = null;
		let payload: any = {};
		payload["id"] = this.filter.company;
		if (!payload["id"]) {
			this.fleetList_dropdown = [];
			return;
		}
		this.apiService.getFleetsByCompany(payload).subscribe((data: any) => {
			this.fleetList_dropdown = data.data;
		}, (error) => {
			this.fleetList_dropdown = [];
		});
	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				this.searchVal = dataTablesParameters.search.value;
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value,
				}
				if (this.filter.company) {
					data["company"] = this.filter.company;
				}
				if (this.filter.fleet) {
					data["fleet"] = this.filter.fleet;
				}
				if (this.filter.platform) {
					data["platform"] = this.filter.platform;
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "mdm_vessel", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoader = null;
					Swal.fire('Success', 'Vessel deleted successfully.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderHC = index;
				this.apiService.deleteHCVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoaderHC = null;
					Swal.fire('Success', 'Vessel deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderHC = index;
				this.apiService.createUpdateVesselInHC({ id: uId }).subscribe(data => {
					this.showCreateLoaderHC = null;
					Swal.fire('Success', 'Vessel created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmCLIP(uId: any, index: number) {
		let platform = "CLIP";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderCLIP = index;
				this.apiService.deleteCLIPVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoaderCLIP = null;
					Swal.fire('Success', 'Vessel deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmCLIP(uId: any, index: number) {
		let platform = "CLIP";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderCLIP = index;
				this.apiService.createUpdateVesselInClip({ id: uId }).subscribe(data => {
					this.showCreateLoaderCLIP = null;
					Swal.fire('Success', 'Vessel created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderMHS = index;
				this.apiService.deleteMHSVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoaderMHS = null;
					Swal.fire('Success', 'Vessel deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderMHS = index;
				this.apiService.createUpdateVesselInMHS({ id: uId }).subscribe(data => {
					this.showCreateLoaderMHS = null;
					Swal.fire('Success', 'Vessel created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderPharma = index;
				this.apiService.deletePharmaVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoaderPharma = null;
					Swal.fire('Success', 'Vessel deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderPharma = index;
				this.apiService.createUpdateVesselInPharma({ id: uId }).subscribe(data => {
					this.showCreateLoaderPharma = null;
					Swal.fire('Success', 'Vessel created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmTraining(uId: any, index: number) {
		let platform = "Training";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderTraining = index;
				this.apiService.deleteTrainingVessel({ id: uId }).subscribe(data => {
					this.showDeleteLoaderTraining = null;
					Swal.fire('Success', 'Vessel deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderTraining = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmTraining(uId: any, index: number) {
		let platform = "Training";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create vessel <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderTraining = index;
				this.apiService.createUpdateVesselInTraining({ id: uId }).subscribe(data => {
					this.showCreateLoaderTraining = null;
					Swal.fire('Success', 'Vessel created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderTraining = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	get f() { return this.resetPasswordForm.controls; }

	updateVesselInventory() {
		Swal.fire({
			title: 'Please confirm !!!',
			html: '<p>Are you sure you want to update vessel inventory?</p>',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Update',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.updateInventoryLoader = true;
				this.apiService.updateVesselInventary().subscribe(data => {
					this.updateInventoryLoader = false;
					Swal.fire('Success', 'Vessel inventory updated successfully', 'success');
				}, (error) => {
					this.updateInventoryLoader = false;
					if (error.status == 401) { return }
					Swal.fire('Error', 'Something went wrong.', 'error');
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	downloadExcel() {
		let payload: any = {};
		if (this.searchVal) {
			payload["searchVal"] = this.searchVal;
		}
		if (this.filter.company) {
			payload["company"] = this.filter.company;
		}
		if (this.filter.fleet) {
			payload["fleet"] = this.filter.fleet;
		}
		if (this.filter.platform) {
			payload["platform"] = this.filter.platform;
		}
		this.excelLoader = true;
		this.apiService.mdm_vessel_download(payload).subscribe(data => {
			this.excelLoader = false;
			let excelData: any = data.body;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Vessels" + ".xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.excelLoader = false;
			if (error.status == 401) { return }
			Swal.fire('Error', 'Something went wrong.', 'error');
		});
	}

	clipText(text: any, limit: any) {
		if (!text) {
			return text;
		}
		limit = limit ? parseInt(limit, 10) : 10;
		let trail = '...';
		return text.length > limit ? text.substring(0, limit) + trail : text;
	}

	mailIndex: any = null;
	mailUId: any = null;

	get f1() { return this.moduleForm.controls; }

	showError(field: string) {
		if (this.f1[field].invalid && (this.f1[field].touched || this.f1[field].dirty)) {
			return true;
		}
		return false;
	}

	openSendMailModal(uId: any, index: number) {
		if (!this.dataList[index].email) {
			this.toastr.error("No email id in vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.mailIndex = index;
		this.mailUId = uId;
		$("#sendMailModal").modal("show");
	}

	clearSendMailModal() {
		this.showMailLoader = null;
		this.mailIndex = null;
		this.mailUId = null;
		this.moduleForm.reset();
		$("#sendMailModal").modal("hide");
	}

	sendMail() {
		if (!this.dataList[this.mailIndex].email) {
			this.toastr.error("No email id in vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.showMailLoader = this.mailIndex;
		let payload: any = {
			id: this.mailUId,
			cc_emails: this.f1.cc_emails.value ? this.f1.cc_emails.value : [],
			bcc_emails: this.f1.bcc_emails.value ? this.f1.bcc_emails.value : [],
		}
		this.apiService.sendWelcomeMail(payload).subscribe(data => {
			this.clearSendMailModal();
			this.toastr.success("Mail sent successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.showMailLoader = null;
			if (error.status == 401) { return }
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	sendMailOld() {
		if (!this.dataList[this.mailIndex].email) {
			this.toastr.error("No email id in vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to send mail to <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[this.mailIndex].email + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {
				this.showMailLoader = this.mailIndex;
				this.apiService.sendWelcomeMail({ id: this.mailUId }).subscribe(data => {
					this.showMailLoader = null;
					Swal.fire('Success', 'Mail sent successfully.', 'success');
				}, (error) => {
					this.showMailLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}
}

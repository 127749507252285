import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { FormControl } from '@angular/forms';
declare var $: any;

@Component({
	selector: 'app-dashboard-inventory',
	templateUrl: './dashboard-inventory.component.html',
	styleUrls: ['./dashboard-inventory.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardInventoryComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	user_type: any = "";
	isCompanyIdNull = false;
	accessLoader = false;
	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) { }
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.accessLoader = true;
			this.apiService.getUserAccess().subscribe((data: any) => {
				this.accessLoader = false;
				try {
					this.user_type = data.res[0].user_type;
					let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
					this.UserModuleAccess = accessObj["PHARMA_dashboard"];
					if (this.user_type != 'cdm') {
						let company = data.res[0].company_id[0];
						this.cardsFilter.company_id = company;
						this.isCompanyIdNull = company.pharma_id ? false : true;
						this.getCountryList();
						/* this.bindMap(); */
						this.PharmaTotalShipment();
					} else {
						if (sessionStorage.getItem("selectedCompanyFilter")) {
							try {
								let obj = JSON.parse(sessionStorage.getItem("selectedCompanyFilter")!);
								if (obj.pharma_id) {
									this.cardsFilter.company_id = obj;
								}
							} catch (error) { }
						}
						this.getPharmaTop10PurchaseMedicines();
						this.getPharmaTop10SaleMedicines();
						this.getCountryList();
						/* this.bindMap(); */
						this.PharmaTotalShipment();
						this.dashboard_company_dropdown();
					}
				} catch (error) { }
			}, (error: any) => {
				this.accessLoader = false;
				this.UserModuleAccess = "NoAccess";
			});

		});
	}

	ngAfterViewInit(): void { }

	ngOnDestroy(): void { }

	pagetitle = "Pharma";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("inventory"),
			name: this.pagetitle
		}
	];
	currentAccordion = "InvoiceModuleAlerts";
	markers: any[] = [];

	pharmaShipmentLoader = false;
	shipment: any = {
		count: 0
	}

	cardsFilter: any = {
		company_id: null,
		start_date: this.apiService.getDefaultDateRange1Month(false).start_date,
		end_date: this.apiService.getDefaultDateRange1Month(false).end_date
	}

	companyList_dropdown: any = [];
	dashboard_company_dropdown() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			data.data = data.data.filter((comp: any) => comp.pharma_id)
			this.companyList_dropdown = data.data;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}

	setCompany(comp: any) {
		if (comp) {
			sessionStorage.setItem("selectedCompanyFilter", JSON.stringify(comp));
		} else {
			sessionStorage.removeItem("selectedCompanyFilter");
		}
	}

	PharmaTotalShipment() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.pharma_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		};
		this.pharmaShipmentLoader = true;
		this.apiService.PharmaTotalShipment(payload).subscribe((data: any) => {
			this.pharmaShipmentLoader = false;
			this.markers = data.data;
			this.markers = this.markers.filter((c: any) => {
				try {
					return !c.name.includes("(0)");
				} catch (error) {
					return false;
				}
			})
			this.shipment.count = data.total_count;
			setTimeout(() => {
				this.bindMap();
				this.addMapPoint();
			}, 200);
		}, (error) => {
			this.pharmaShipmentLoader = false;
			this.shipment.count = "-";
			this.markers = [];
			setTimeout(() => {
				this.bindMap();
				this.addMapPoint();
			}, 200);
		});
	}

	bindMap() {
		$('#worldmap').vectorMap({
			map: 'world_mill',
			scaleColors: ['#C8EEFF', '#0071A4'],
			normalizeFunction: 'polynomial',
			hoverOpacity: 0.7,
			hoverColor: false,
			markerStyle: {
				initial: {
					fill: '#F8E23B',
					stroke: '#383f47'
				}
			},
			backgroundColor: '#999999'
		});
	}

	addMapPoint() {
		this.removeAllMapPoint();
		var map = $('#worldmap').vectorMap('get', 'mapObject');
		var colorArr = ['#009933', '#FF8C00', "#FF0000", "#ff7d65", "#FFFF66", "#3f51b5", "#eba134", "#a2eb34", "#6beb34", "#00d998", "#0089d9", "#0045d9", "#3a26ed", "#9326ed", "#cc26ed", "#ed26c2", "#ed2683", "#ed265b", "#ed262d"];
		for (let i = 0; i < this.markers.length; i++) {
			this.markers[i].style = { fill: colorArr[i] ? colorArr[i] : "#ed262d", r: 5 };
		}
		map.addMarkers(this.markers, []);
	}

	removeAllMapPoint() {
		var map = $('#worldmap').vectorMap('get', 'mapObject');
		map.removeAllMarkers();
	}

	filterChanged() {
		this.accordionOpened(this.currentAccordion);
		this.PharmaTotalShipment();
		this.getPharmaTop10PurchaseMedicines();
		this.getPharmaTop10SaleMedicines();
	}
	accordionOpened(id: any) {
		this.currentAccordion = id;
		if (id == 'InvoiceModuleAlerts') {
			this.getInvoiceModuleAlerts();
		} else if (id == 'TaskManagementAlerts') {
			this.getTaskAlerts();
		} else if (id == 'CompletedOrdersReport') {
			this.getCompletedOrdersReport();
		} else if (id == 'FreightBillingReport') {
			this.getFreightBillingReport();
		} else if (id == 'CompanyReport') {
			this.getCompanyReport();
		}
	}

	invoiceAlerts: any[] = [];
	loadingInvoiceAlerts = false;
	invoiceAlertsExcelLoader = false;
	invoiceExcelName = "Purchase Orders Report.xlsx";
	invoiceHeaderName = "Purchase Orders Report";
	getInvoiceModuleAlerts() {
		this.loadingInvoiceAlerts = true;
		this.apiService.simpleGetAPICall("InvoiceModuleAlerts", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.loadingInvoiceAlerts = false;
			this.invoiceAlerts = data;
		}, (error) => {
			this.loadingInvoiceAlerts = false;
			this.invoiceAlerts = [];
		});
	}
	getInvoiceModuleAlertsExcel(e: any) {
		e.stopPropagation();
		this.invoiceAlertsExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("InvoiceModuleAlertsExcelDownload", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe(res => {
			let excelData: any = res.body;
			this.invoiceAlertsExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.invoiceExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.invoiceAlertsExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	taskAlerts: any[] = [];
	loadingTaskAlerts = false;
	taskAlertsExcelLoader = false;
	taskExcelName = "Task Management.xlsx";
	taskHeaderName = "Task Management";
	getTaskAlerts() {
		this.loadingTaskAlerts = true;
		this.apiService.simpleGetAPICall("TaskManagementAlerts", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.loadingTaskAlerts = false;
			this.taskAlerts = data;
		}, (error) => {
			this.loadingTaskAlerts = false;
			this.taskAlerts = [];
		});
	}
	getTaskkModuleAlertsExcel(e: any) {
		e.stopPropagation();
		this.taskAlertsExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("TaskManagementAlertsExcelDownload", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe(res => {
			let excelData: any = res.body;
			this.taskAlertsExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.taskExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.taskAlertsExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	CompletedOrdersReport: any[] = [];
	CompletedOrdersReportLoader = false;
	CompletedOrdersReportExcelLoader = false;
	CompletedOrdersReportExcelName = "Completed Orders Report.xlsx";
	CompletedOrdersReportHeader = "Completed Orders Report";
	getCompletedOrdersReport() {
		let payload: any = {
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.pharma_id : null
		};
		this.CompletedOrdersReportLoader = true;
		this.apiService.simpleGetAPICall("CompletedOrdersReport", payload).subscribe((data: any) => {
			this.CompletedOrdersReportLoader = false;
			this.CompletedOrdersReport = data;
		}, (error) => {
			this.CompletedOrdersReportLoader = false;
			this.CompletedOrdersReport = [];
		});
	}
	getCompletedOrdersReportExcel(e: any) {
		e.stopPropagation();
		this.CompletedOrdersReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CompletedOrdersReportExcelDownload", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe(res => {
			let excelData: any = res.body;
			this.CompletedOrdersReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CompletedOrdersReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CompletedOrdersReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	FreightBillingReport: any[] = [];
	FreightBillingReportLoader = false;
	FreightBillingReportExcelLoader = false;
	FreightBillingReportExcelName = "Freight Billing Report.xlsx";
	FreightBillingReportHeader = "Freight Billing Report";
	getFreightBillingReport() {
		this.FreightBillingReportLoader = true;
		this.apiService.getFreightBillingReport({ start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.FreightBillingReportLoader = false;
			this.FreightBillingReport = data;
		}, (error) => {
			this.FreightBillingReportLoader = false;
			this.FreightBillingReport = [];
		});
	}
	getFreightBillingReportExcel(e: any) {
		e.stopPropagation();
		this.FreightBillingReportExcelLoader = true;
		this.apiService.getFreightBillingReportExcel({ start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe(res => {
			let excelData: any = res.body;
			this.FreightBillingReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.FreightBillingReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.FreightBillingReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	CompanyReport: any[] = [];
	CompanyReportLoader = false;
	CompanyReportExcelLoader = false;
	CompanyReportExcelName = "Statement Report.xlsx";
	CompanyReportHeader = "Statement Report";
	getCompanyReport() {
		let payload: any = {
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.pharma_id : null,
		}
		this.CompanyReportLoader = true;
		this.apiService.getCompanyReport(payload).subscribe((data: any) => {
			this.CompanyReportLoader = false;
			this.CompanyReport = data.data;
		}, (error) => {
			this.CompanyReportLoader = false;
			this.CompanyReport = [];
		});
	}
	getCompanyReportExcel(e: any) {
		e.stopPropagation();
		let payload: any = {
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.pharma_id : null,
		}
		this.CompanyReportExcelLoader = true;
		this.apiService.getCompanyReportExcel(payload).subscribe(res => {
			let excelData: any = res.body;
			this.CompanyReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CompanyReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CompanyReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	Top10PurchaseMedicines: any[] = [];
	Top10PurchaseMedicinesLoader = false;
	Top10PurchaseMedicinesHeader = "Top 10 Purchase Medicines";
	getPharmaTop10PurchaseMedicines() {
		this.Top10PurchaseMedicinesLoader = true;
		this.apiService.getPharmaTop10PurchaseMedicines({ start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.Top10PurchaseMedicinesLoader = false;
			this.Top10PurchaseMedicines = data.data;
		}, (error) => {
			this.Top10PurchaseMedicines = [];
			this.Top10PurchaseMedicinesLoader = false;
		});
	}

	Top10SaleMedicines: any[] = [];
	Top10SaleMedicinesLoader = false;
	Top10SaleMedicinesHeader = "Top 10 Sale Medicines";
	getPharmaTop10SaleMedicines() {
		this.Top10SaleMedicinesLoader = true;
		this.apiService.getPharmaTop10SaleMedicines({ start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.Top10SaleMedicinesLoader = false;
			this.Top10SaleMedicines = data.data;
		}, (error) => {
			this.Top10SaleMedicines = [];
			this.Top10SaleMedicinesLoader = false;
		});
	}

	CountryList: any[] = [];
	CountryListLoader = false;
	countryExcelLoader = false;
	countryExcelName = "Shipment Cost By Country.xlsx";
	CountryListHeader = "Shipment Cost By Country";
	countrySearchControl = new FormControl("");
	getCountryList() {
		this.CountryListLoader = true;
		this.apiService.getCountryList({}).subscribe((data: any) => {
			this.CountryListLoader = false;
			this.CountryList = data.data;
			this.CountryList = this.CountryList.filter((val: any) => val.total_avg);
			this.CountryList = this.CountryList.map((val: any) => {
				val.total_gross_weight = Math.round((val.total_gross_weight + Number.EPSILON) * 100) / 100;
				val.total_avg = Math.round((val.total_avg + Number.EPSILON) * 100) / 100;
				return val;
			})
		}, (error) => {
			this.CountryList = [];
			this.CountryListLoader = false;
		});
	}

	searchCountry(index: number) {
		let searchMatched = false;
		try {
			let searchTerm: any = this.countrySearchControl.value;

			if (!searchTerm) {
				searchMatched = true;
			}
			let countryName: any = this.CountryList[index].country_id__country_name;
			if (countryName) {
				if (countryName.toLowerCase().includes(searchTerm.toLowerCase())) {
					searchMatched = true;
				}
			} else {
				searchMatched = true;/* If country name is null or blank then it will be shown */
			}
		} catch (error) {
			searchMatched = true;
		}
		return searchMatched;
	}

	getCountryExcel(e: any) {
		e.stopPropagation();
		this.countryExcelLoader = true;
		this.apiService.getCountryWiseShipmentExcel().subscribe(res => {
			let excelData: any = res.body;
			this.countryExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.countryExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.countryExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}
}
